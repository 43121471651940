<template>
  <div class="md-layout md-alignment-center">
    <section id="team" class="md-layout-item md-size-100">
      <h1 class="md-center md-title branco">
        CONHEÇA A EQUIPE DE <span>TECNOLOGIA DIGITAL</span>!
      </h1>
      <p class="md-body-2 branco">
        Ligado no aumento da demanda por profissionais qualificados na área de
        desenvolvimento de games, o Projeto Economia do Bem oferece a jovens dispostos a
        desenvolver soluções criativas usando inovação e tecnologia oficinas com criação de
        jogos. Como bolsistas, os jovens atuam como Promotores de Tecnologia Digital do Ateliê
        de Ideias, passando por formação presenciais e/ou online através da plataforma de
        Ensino a Distância (AVA do Ateliê), As aulas começaram, em outubro de 2020, com
        Ramon Matheus e Sheila Nogueira, falando sobre direitos da Juventude, direito de
        acesso à tecnologia e consumo consciente.
      </p>
      <p class="md-body-2 branco">
        Partindo para criação do jogo, contamos com os facilitadores Ricardo Bonino, Walber
        Beltrame e Marcos Romanholi. Eles se amarraram no desafio de desenvolver com os
        jovens um jogo sobre Consumo Consciente e pensar em outras ferramentas digitais que
        colaborem com o desenvolvimento comunitário do Território do Bem.
      </p>
    </section>

    <section
      id="savio"
      class="member md-center md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
    >
      <img alt="Sávio de Lima Varejão" src="@/assets/savio.png" width="312" />
      <h1 class="md-title">Sávio Varejão</h1>
      <p class="md-body-2">
        Nascido e criado no Território do Bem, Sávio tem uma fascinação pela tecnologia,
        principalmente por meio dos videogames - e hoje está trabalhando na criação de um
        game, como nunca imaginou antes! Ele é cofundador do coletivo Jovens do Bem, e
        pensa um dia levar essa experiência para dentro do coletivo. &quot;A tecnologia hoje está por
        toda parte, é muito importante estarmos ligado a esse novo universo&quot;, diz.
      </p>
      <p class="md-body-2">
        &quot;Sempre curti jogar, mas nunca tive contato com o que tem por trás de um jogo. Quero
        conhecer o universo dos games, como são feitos e programados, o que existe por trás
        dos gráficos e jogabilidade. Essa é mais uma iniciativa muita boa do Ateliê&quot;.
      </p>
    </section>

    <section
      id="george"
      class="member md-center md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
    >
      <img
        alt="George Cirilo do Nascimento"
        src="@/assets/george.png"
        width="312"
      />
      <h1 class="md-title">George Cirilo</h1>
      <p class="md-body-2">
        George, do bairro Itararé, é graduando em Engenharia Elétrica, mas se há uma coisa de
        que ele sempre gostou é estudar tecnologia digital!
      </p>
      <p class="md-body-2">
        Como um dos fundadores do coletivo Tec Perifa, em suas palavras, “um coletivo criado
        com intuito de apresentar aos jovens periféricos uma atividade formativa usando a
        tecnologia como ferramenta”, ele espera no futuro repassar seu conhecimento para sua
        comunidade com cursos de qualidade e equipamentos de ponta.
      </p>
    </section>

    <section
      id="ingrid"
      class="member md-center md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
    >
      <img
        alt="Ingrid da Rocha Ricardo"
        src="@/assets/ingrid.png"
        width="312"
      />
      <h1 class="md-title">Ingrid Rocha</h1>
      <p class="md-body-2">
        Fotógrafa, artesã, produtora, Bacharel em Psicologia e Técnica em Multimídia, Ingrid é
        mais uma jovem de Itararé cheia de sonhos e conquistas!
      </p>
      <p class="md-body-2">
        Ela já trabalha com sua arte e gerencia diversos perfis em redes sociais, e busca
        aprender mais na formação em Tecnologia Digital para construir sua carreira como
        empresária em projetos sociais para a periferia.
      </p>
    </section>

    <section
      id="leonardo"
      class="member md-center md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
    >
      <img
        alt="Leonardo Fábio Rodrigues Camargo"
        src="@/assets/leonardo.png"
        width="312"
      />
      <h1 class="md-title">Leonardo Camargo</h1>
      <p class="md-body-2">
        &quot;Quase tudo nos dias de hoje necessita de um sistema de informação; se você não tem,
        é deixado para trás&quot;. Esse é o lema de Leonardo, jovem visionário que cresceu no Morro
        do Quadro e vê o mundo se transformando em oportunidades através da tecnologia.
      </p>
      <p class="md-body-2">
        Ele já trabalha com edição voltada para jogos online, e se juntou à equipe do Ateliê para,
        segundo ele, &quot;trabalhar na área de desenvolvimento de aplicativos mais complexos e
        completos, de modo que supra não só a necessidade e produção do mercado, como a
        sociedade no geral&quot;.
      </p>
    </section>

    <section
      id="gustavo"
      class="member md-center md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
    >
      <img alt="Gustavo Mateus Viana" src="@/assets/gustavo.png" width="312" />
      <h1 class="md-title">Gustavo Bello</h1>
      <p class="md-body-2">
        Diretamente de São Benedito, Gustavo é comunicador nato e acredita no poder de
        transformação que a Tecnologia Digital tem. Seu objetivo é ser publicitário um dia, e usar
        toda sua criatividade para atingir as pessoas, não só da periferia, como do mundo afora.
      </p>
      <p class="md-body-2">
        &quot;Estou sempre ajudando na divulgação de projetos e serviços nas redes sociais para
        ajudar amigos, familiares, conhecidos e coisas com que me identifico. Quero trabalhar na
        comunicação, e participar da gestão de vários projetos no Território&quot;
      </p>
    </section>

    <section
      id="jean"
      class="member md-center md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
    >
      <img alt="Jean Cirilo" src="@/assets/jean.png" width="312" />
      <h1 class="md-title">Jean Cirilo</h1>
      <p class="md-body-2">
        Jean, morador do bairro Itararé é graduando em Design na UFES e é apaixonado pelo mundo da ilustração e dos games desde muito pequeno!
      </p>
      <p class="md-body-2">
        "Sempre gostei muito de desenhar e mexer com todo o tipo de arte, e desde criança sou fascinado pelo mundo dos vídeo games, 
        observo muito a parte visual desse universo tão rico. E sempre quis saber como era o processo de produção dessas obras de arte. 
        E aqui no Ateliê de Ideais  tive a chance de ver exatamente como isso é feito".
      </p>
    </section>

    <section
      id="johnston"
      class="member md-center md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
    >
      <img alt="Johnston Ferreira" src="@/assets/johnston.png" width="312" />
      <h1 class="md-title">Johnston Ferreira</h1>
      <p class="md-body-2">
        Apaixonado por tecnologia desde pequeno, Jhonston estudou programação de forma
        esporádica. Frequentador e amante do Território do Bem, encontrou nesse projeto a
        oportunidade de desenvolver seu conhecimento e adquirir mais experiência.
      </p>
      <p class="md-body-2">
        &quot;Gosto muito de programação de sistemas, é desafiante, e me mostra cada vez mais
        que, para tudo, há uma ou até várias soluções (risos) usando algoritmos, desde um
        pequeno problema até o grande chefão deles. Nesse projeto também conheci novas
        pessoas e personalidades diferentes, e ver como conseguimos passar por cada desafio
        foi bem interessante, muitas ideias e bastante mão na massa&quot;.
      </p>
    </section>

    <section
      id="ludimila"
      class="member md-center md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-50 md-small-size-100 md-xsmall-size-100"
    >
      <img alt="Ludimila Brandão Falcão " src="@/assets/ludimila.png" width="312" />
      <h1 class="md-title">Ludimila Brandão Falcão </h1>
      <p class="md-body-2">
        Criada em São Francisco, Cariacica, e frequentadora assídua do Território do Bem, mudou-se em 2020 para o Itararé. 
        Como desenhista, Ludimila usa as redes sociais para divulgar os trabalhos artísticos que produz.
      </p>
      <p class="md-body-2">
        "Fazer parte desse projeto é uma experiência desafiadora e muito incrível. 
        Desafiadora, porque nunca me imaginei por de trás dos monitores na criação de um jogo, apesar de gostar bastante de jogar, 
        fazer é algo novo e bastante interessante . É uma oportunidade incrível conhecer esse universo dos games, aprender sobre programação, 
        além de por em prática e aperfeiçoar o que eu sei de arte e de desenho que vem acrescentar  a esse projeto tão massa".
      </p>
    </section>
  </div>
</template>
<script>
export default {};
</script>